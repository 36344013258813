.thumbnail { display: block; position: relative; padding: 4px; margin-bottom: 20px; line-height: 1.42857143; background-color: #fff; border: 1px solid #ddd; border-radius: 4px; -webkit-transition: border .2s ease-in-out; -o-transition: border .2s ease-in-out; transition: border .2s ease-in-out; }
.member{
    .member-image{ position: relative; }
    .member-name{ margin-top: 10px; font-weight : bold; text-transform: uppercase; text-align: center; }
    .dropdown-divider{ margin: 0;}
}
.custom-link{ cursor: pointer;}
.custom-link:hover{ background-color: #f2f4f5 !important; }
.custom-link:active{ background-color: #007bff !important; }

.custom-link:hover span{ color: #000; }
.member .member-image{ position: relative; }
.member .member-image img{ width: 150px; height: 150px; border-radius: 50%; pointer-events: none; margin-top: 15px; }
.member .member-image .on-profile{ position: absolute; color: #a840db; bottom: 30px; right: 43px; font-size: 45px;padding: 5px;}
.member .member-name{ margin-top: 10px; font-weight : bold; text-transform: uppercase; text-align: center; }
.member .member-id{ font-weight : bold; text-transform: uppercase; text-align: center; font-size: 80%; }
.member ul.member-social { list-style-type: none; margin: 0; padding: 0; }
.member ul.member-social li { text-align: center; }
.member ul.member-social .member-phone, .member ul.member-social .member-email { font-weight: 500; }
.member ul.member-social .member-email { text-decoration: underline; text-transform: lowercase; }
.btn-group.member-options { cursor: pointer; position: absolute; top: 0px; right: 0px; }
.btn-group.member-options .opts{ width: 20px; height: 20px; display: block; position: relative; }
.btn-group.member-options .opts i.fa-ellipsis-v{ top: 3px; left: 9px; right: 0; position: absolute; bottom: 0; color: #000; }
.btn-group.member-options .dropdown-menu{ padding: 0; }
.btn-group.member-options .dropdown-menu ul{ list-style-type: none; padding: 0; margin: 0; }
.btn-group.member-options .dropdown-menu ul li{ padding: 10px; }
.btn-group.member-options .dropdown-menu ul li:hover{ background-color:#dedede; }
.btn-group.member-options .dropdown-menu ul li a{ text-decoration: none; color: #000; }

@media (min-width: 992px){ #team .members .member{ width: 20%; } }

/** Add Member */
.member-image-upload-area{ background-color: #ececec; padding: 15px; }
.member-options .btn-group button{ background: none; box-shadow: none; outline:none; }
.member-options .btn-group .dropdown-menu{ margin: 0.3rem 0 0 20px; }
.iconlogin { color:#dc1e38; font-size:45px}

.text-container {
    text-align: center;
    color: #2c3e50; /* Adjust color as needed */
  }
