.AddsysMaintaintcontainer .error  {
    display: none;
}
.eqalertcontainer{
 
    .clearfix{
        clear: both;
    }
    .databoxcomponent{
        .box{
            font-weight: 500;
            border: 0.15rem solid;
            padding: 0.7rem 0.3rem 0.7rem 0.3rem;
            .countholder{
                color: white;
                position: absolute;
                right: 4px;
                top: 4px;
                padding: 0.8rem;
            }
        }
        @media (max-width: 480px) and (min-width: 320px){
            .box{
                font-size: 1rem;
            }
        }
        .boxred{
            color:white;
            background-color:#dc1e38;
            border-color: #dc1e38;
        }
        .boxgreen{
            color: #28a745;
             .countholder{
                background-color:#28a745;
            }
        }
        .boxyellow{
            color:#ffc107;
             .countholder{
                background-color: #ffc107;
            }
        }
        .boxprimary{
            color: #464a4e;
            .countholder{
                background-color: #464a4e;
            }
        }
    }
}

.AddsysMaintaintcontainer{
    .eqdetailbox{
        .detailcover{
            padding: 1rem;
            .react-datepicker__time-list{
                padding: 0;
                padding-right: 0px!important;
            }
            .title{
                width: 95px;
                font-weight: bold;
                margin-top: 5px;
            }
            .calLogo{
                float: left;
                font-size: 1.5rem;
                margin: 0.1rem 0rem 0.1rem 5px;
            }
            .selectwrapper{
                select{
                    padding: 0rem;
                }
            }
            .width70{
                width: 70%;
            }
            .fullwidth{
                width: 100%;
            }
            textarea{
                height: 144px;
                resize: none;
                border-color: #ced4da;
            }
        }
    }
}
