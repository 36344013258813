.dashed{ border-bottom: 1px dashed #999}
.red-dashed{ border-bottom: 1px dashed #f00; color: #f00;}
.highlighted-row{
    background-color: #ffffb3 !important;
}
.highlighted-row .btn{
    vertical-align: middle;
    margin-top: 0% !important;
}
.clearbtn{
    margin: 0px;
    border: 0px currentColor !important;
    border-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
}
.tracker-details{
    margin-bottom: 10px;
    cursor: pointer;
}
.btn-move-setting{
    //text-align: left; 
    position:absolute;
    bottom:0;                          
    left:0; 
}

.auto-index {
    counter-reset: Serial; /* Set the serial number counter to 0 */
   
   }

.auto-index td:first-child:before{
    counter-increment: Serial;      /* Increment the Serial counter */
    content: counter(Serial); /* Display the counter */
}
/* .innerrowscroll{
    max-height: 340px;
    overflow-y: scroll;
} */

table.scroll tbody,
table.scroll thead { display: block;
width: 100% }

table.scroll tbody {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    
}

  





