.c-alert-setting {
    display: flex;
    flex-direction: column;
    position: relative;
    .tractor-info{
        display: flex;
        padding: 5px;
        .left{
            margin-right: 20px;
            p {
                margin: 0;
                text-align: center;
                background: rgba(1,1,1,0.8);
                color: white;
                padding: 5px 0;
            }
        }
        .right {
            display: flex;
            ul {
                margin: 0;
                padding: 0;
                align-self: center;
                li {
                    list-style-type: none;
                    padding: 5px;
                    display: flex;
                    color: #333;
                    align-items: center;
                    span {
                        font-weight: bold;
                        text-transform: uppercase;
                        width: 150px;
                    }
                }
            }
        }
    }
    .haserr{
        border: 1px solid #dc1e38;
    }
    @media (min-width:768px) {
        height: auto;
    }
    & {
        &-header {
            display: none;
            @media (min-width:768px){
                display: flex;
            }
            .search-equipment {
                .btn-search-equipment {
                    width: 200px;
                    margin-left: auto;
                }
            }
        }
        &-search-equiment {
            label {
                &.inputLabel {
                    width: 20%;
                    text-transform: uppercase;
                }
                &.inputboxLabel {
                    padding-left: 30px;
                    margin-right: 30px;
                    &:last-child{
                        margin-right:0;
                    }
                }
            }
            .selectwrapper {
                display: inline-block;
                select {
                    padding: 0 10px;
                }
            }
        }
        .load {
            font-weight: bold;
            padding: 5px 10px;
        }
        .setting {
            width: 100%;
            div {
                &.form-group {
                    display: inline-block;
                    width: auto;
                    &:first-child {
                        width: 16%;
                    }
                    &.margin {
                        margin-left: 19px;
                    }
                    label {
                        display: block;
                        margin: 0;
                    }
                    input {
                        &.form-control {
                            float: left;
                            width: 70px;
                        }
                    }
                    select {
                        width: 70px;
                        height: 37px;
                    }
                    span {
                        margin: 8px 18px;
                        /* float: left; */
                    }
                    .ampm{
                        margin-left: 20px;
                    }
                }
                &.checkbox-group {
                    display: inline-block;
                    position: relative;
                    padding-left: 30px;
                    font-size: 16px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                    label {
                        display: inline-block;
                        margin-bottom: 5px;
                        cursor: pointer;
                        line-height: 30px;
                    }
                    .checkmark {
                        position: absolute;
                        top: 3px;
                        left: 0;
                        height: 20px;
                        width: 20px;
                        border-radius: 2px;
                        border: 1px solid #ccc;
                        margin: 0;
                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                    }
                    &:hover input ~ .checkmark {
                        background-color: #ccc;
                    }
                    input:checked ~ .checkmark {
                        background-color: #dc1e38;
                        border-color: #dc1e38;
                        &:after {
                            display: block;
                        }
                    }
                    .checkmark:after {
                        left: 6px;
                        top: 1px;
                        width: 7px;
                        height: 14px;
                        border: 1px solid #ffffff;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
                section {
                    display: inline-block;
                    div { 
                        &.form-group{
                            &:first-child {
                                width: auto;
                            }
                        }
                    }
                }
            }
            .geofencing {
                border: 1px solid #f0f0f0;
                padding: 10px;
                margin: 5px 10px;
                width: 98%;
                h3 {
                    margin: 15px 0;
                }
            }
            .setting-cta {
                display: block;
                button {
                    &.btn-save-setting {
                        margin-left: 30px;
                        
                    }
                    &.btn-clear {
                        float: right;
                    }
                }
            }
        }
        
        @media (min-width: 767px) and (max-width: 991px) {
            &-search-equiment {
                label {
                    &.inputLabel {
                        width: 100%;
                    }
                }
            }
            .setting{
                .col-lg-12{
                    .form-group{
                        label{
                            width: 139px;
                        }
                    }
                }
                div {
                    &.form-group {
                        &:first-child {
                            width: 140px;
                        }
                    }
                }
                .error-field{
                    width: 50%;
                    .checkbox-group{
                        label{
                            width: 100%;
                        }
                    }
                }
            }
        }
        @media (max-width: 670px) {
             &-search-equiment {
                label {
                    &.inputLabel {
                        width: 100%;
                    }
                }
             }
             .setting{
                .col-lg-12{
                    .form-group{
                        label{
                            width: 139px;
                        }
                    }
                }
                div {
                    &.form-group {
                        &:first-child {
                            width: 140px;
                        }
                    }
                }
                .error-field{
                    width: 50%;
                    .checkbox-group{
                        label{
                            width: 100%;
                        }
                    }
                }
            }
        }
        @media (min-width: 320px) and (max-width: 480px) {
            &-search-equiment {
                label {
                    &.inputLabel {
                        width: 100%;
                    }
                }
            }
            .setting{
                .col-lg-12{
                    .form-group{
                        label{
                            width: 120px;
                        }
                    }
                }
                div {
                    &.form-group {
                        &:first-child {
                            width: 140px;
                        }
                    }
                }
                .error-field{
                    width: 50%;
                    .checkbox-group{
                        label{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
