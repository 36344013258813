.c-location-information {
    display: flex;
    flex-direction: column;
    position: relative;
    @media (min-width:768px) {
        height: auto;
    }
div.mapArea.row{
        margin-top: 20px;
    }
    .conatiner {
        .multiselect {
            display: block;
            border: 1px solid #f0f0f0;
            padding: 8px 14px 0px;
            padding-right: 0;
            position: relative;
            margin-bottom: 25px;
            p{
                font-weight: bold;
                cursor:pointer;
                margin-bottom: 8px;
                svg{
                    float: right;
                    margin-right: 11px;
                    margin-top: 3px;
                }
            }
            .form-group {
                .custom-control {
                    padding-bottom: 5px;
                }
            }
            .checkbox-group {
                display: block;
                position: relative;
                padding-left: 30px;
                margin-bottom: 5px;
                font-size: 16px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                label {
                    display: inline-block;
                    margin-bottom: 5px;
                    cursor: pointer;
                }
                .checkmark {
                    position: absolute;
                    top: 3px;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    border-radius: 2px;
                    border: 1px solid #ccc;
                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                }
                &:hover input ~ .checkmark {
                    background-color: #ccc;
                }
                input:checked ~ .checkmark {
                    background-color: #dc1e38;
                    border-color: #dc1e38;
                    &:after {
                        display: block;
                    }
                }
                .checkmark:after {
                    left: 6px;
                    top: 1px;
                    width: 7px;
                    height: 14px;
                    border: 1px solid #ffffff;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
            &-dropdown {
                max-height: 100px;
                overflow-y: scroll;
            }
            .cta{
                // margin: -11px;
                margin-bottom: 15px;
                display: flex;
                justify-content: flex-start;
                padding-right: 25px;
                input {
                    border-radius: 20px;
                    margin-right: 10px;
                }
                button {
                    &:last-child{
                        margin: 3px 6px 0px;
                    }
                    &:first-child{
                        margin: 2px 3px 0px;
                        width: 100%;
                        padding-left: 3px;
                        padding-right: 3px;
                    }
                }
                @media (min-width:768px) {
                    div{
                        padding: 1px;
                    }
                    div{
                        padding: 1px;
                    }
                }
            }
            .toggle {
                position: absolute;
                top: 38px;
                z-index: 1;
                left: 0;
                right: 0;
                background: #fff;
                padding-top: 20px;
                padding-left: 20px;
                &.show {
                    border: 1px solid #f0f0f0;
                }
            }
        }
      
        .tractor-info{
            display: flex;
            padding: 5px;
            .left{
                margin-right: 20px;
                p {
                    margin: 0;
                    text-align: center;
                    background: rgba(1,1,1,0.8);
                    color: white;
                    padding: 5px 0;
                }
            }
            .right {
                display: flex;
                ul {
                    margin: 0;
                    padding: 0;
                    align-self: center;
                    li {
                        list-style-type: none;
                        padding: 5px;
                        display: flex;
                        color: #333;
                        align-items: center;
                        span {
                            font-weight: bold;
                            text-transform: uppercase;
                            width: 150px;
                        }
                    }
                }
            }
        }
     
        .tractor-status {
            ul {
                display: flex;
                padding: 0;
                margin-top: 20px;
                li {
                    list-style-type: none;
                   // padding: 0px 11px 0px;
                    span {
                        //margin-left: 5px;
                    }
                }
            }
        }
        .btn {
            &.disabled {
                pointer-events: none;
                cursor: no-drop;
            }
        }
    }
}
.hidden {
    display:none;
}
 .show{
    display:block;
}

.checkmark.active {
    background-color: #dc1e38 !important;
    border-color: #dc1e38!important;
}
.sp-equipment-detail{
    .fix-h{
        height: 120px;
        width: 100%;
    }
    .grey-title{
        text-transform: uppercase;
        background-color: #e6e6e6;
        border: 1px solid #cecaca;
        padding: 2px 10px;
    }
    .eqdetails{
        .eqdetailbox{
            .detailcover{
                margin-bottom: 10px;
                /*.float-right{
                    width: 135px;
                }*/
                .auto-width{
                    width: auto;
                }
                .pinned{
                    padding: 4px 15px;
                    display: inline-block;
                    background: gray;
                    color:#fff;
                }
            }
        }
    }
    .toprow{
        margin-bottom: 30px;
        .tractorDetail{
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            color: #fff
        }
    }
    .relative{
        position: relative;
        .route-map-btn{
            position: absolute;
            right: 60px;
            bottom: 5px;
        }
    }
    
    
}
.calLogo {
    //position: absolute;
    border: 0;
    display: inline-flex;
    top: 30px;
    right: 1px;
    font-size: 1.5rem;
    background-color: white;
    padding: 0;
}

.react-datepicker__time-list{
    padding: 0;
    padding-right: 0px!important;
}
/* .tabledashboard thead tr th {
    .firstRow {
        border : '10px solid #e8e8e8';
    }
} */

.tabledashboard {
    overflow-y: scroll;
    max-height: 500px;
}
.tableFixHead thead tr{
    display: block;
}


.tableFixHead tbody {
    display: block;
    height: 500px;
    overflow: auto;
}

.modalClass{
.modal-body{
    position: relative;
    flex: 1 1 auto;
    max-height: 700px; 
    overflow-y: auto
}
.modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2));
      max-height: 500px; 
      max-width: 700px;}
.modal-sm {
      max-width: 300px; }
}
.checkbox-group {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    label {
        display: inline-block;
        margin-bottom: 5px;
        cursor: pointer;
        line-height: 30px;
    }
    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 2px;
        border: 1px solid #ccc;
        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
    input:checked ~ .checkmark {
        background-color: #dc1e38;
        border-color: #dc1e38;
        &:after {
            display: block;
        }
    }
    .checkmark:after {
        left: 6px;
        width: 7px;
        height: 14px;
        border: 1px solid #ffffff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
} 
 .equipmentInfo{
   
    .radioButton-group {
        display: inline-block;
        position: relative;
        margin-right: 30px;
        label {
            margin: 0;
            padding-left: 15px;
            cursor: pointer;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            margin:0;
            &:checked ~ .checkmark {
                background-color: #dc1e38;
                &:after {
                    display: block;
                }
            }
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 18px;
            width: 18px;
            background-color: #d2d2d2;
            border-radius: 50%;
            &:after {
                content: "";
                position: absolute;
                display: none;
            }
            &:after {
                top: 5px;
               left: 5px;
               width: 8px;
               height: 8px;
               border-radius: 50%;
               background: white;
            }
        }
        &:hover input ~ .checkmark {
            background-color: #bdbdbd;
        }
        &:hover input:checked ~ .checkmark {
            background-color: #dc1e38;
        }
    }
    .custom-radio .custom-control-label::before {
        border-radius: 50%;
        background-color: #fff;
     }
 
 }
    
    div[disabled]
        {
        pointer-events: none;
        opacity: 0.7;
        }
.eqService{
    td:nth-child(5) { 
        width: 12%; 
    } 
    td:nth-child(6) { 
        width: 18%; 
    } 

}
.save{
    margin-left: auto; 
    margin-right: 30px;}


