.user-profile{margin-top: 20px;}
.profile-asset,.reset-password-area{margin-top: 15px;}
.upperbold{ text-transform: uppercase; font-weight: 600;}
.error, .required{color:red;}
span.required{ display: inline-block;}
.btn.disabled, .btn:disabled{ cursor: no-drop;}
.custom-reset{ border: none !important; padding: 0 10px !important;}
.user-image{
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
}
.x-time{
    background-color: black;
    color: #fff;
    border-radius: 50%;
    padding: 1px 5px;
    position: absolute;
    top: 0;
    cursor: pointer;
    right: 0;
}