
.header{
    background-color: #dc1e38;
    min-height: 85px;
    width: 100%;
    position: fixed;
    clear: both;
    z-index: 999;
    box-shadow: 0rem 0rem 0.4rem #464a4e;
}

.last-evnt{
    font-size: 60%;
    float: right;
    margin-top: 7px;
    font-style: italic;
    text-transform: capitalize;
}

.inner-header img{
      padding: 22px;
}
.sm-inner-header{
    display: none;
}
.sidebar{
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    max-width: 248px;
    //height: 526px;
    //overflow-y: scroll;
    left: 0;
    margin-top: 3.0rem;
    z-index: 99;
}

.hidebar{
    display: none;
}
.sidebar .navicon{
    color: white;
    font-size: 23px;
    margin: 6px;
    text-align: center;
}

.sidebar .menubtnicon{
	margin: 17px 24px 0px;
}

.adminbtn{
    margin-top: 1.3rem;
    margin-left: 1rem;
}

.dashboardmenu-text{
    margin-top: 17px !important;
}

.sidebartext{
    background-color: #00000094;
    width: 174px;
    height: 900px;
    margin-left: 90px;
    position: absolute;
    padding-top: 55px;
    color: white;
    text-transform: uppercase
}
.navsec{
    clear: both;
}
.navactive{
    color: white!important;
}
.navsec-text{
    height: 37px;
    margin: 28px 7px 0px;
    float: left;
    font-weight: 500;
    color: #b0b0b0;
    width: 133px;
    text-transform: uppercase;
    -webkit-transition: display 3s;
    transition: display 3s;
}
.navsec-text-service{
    height: 37px;
    margin: 28px 7px 0px;
    float: left;
    font-weight: 500;
    color: #b0b0b0;
    width: 230px;
    text-transform: uppercase;
    -webkit-transition: display 3s;
    transition: display 3s;
}
.navsec-text:hover{
    color: white;
}
.sidebartext div{
	padding: 23px 22px 10px;
}
.menuwrapper{
    background-color: #00000052;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 20px 20px 9px;
    float: left;
}
.sidebar .menuwrapper:hover {
	color: white;
    background-color: #00000036;
}
.activemenu{
	background-color: #dc1e38!important;
}
.header-text{
    color: #dc1e38;
    text-transform: uppercase;
    font-size: 19px;
    margin-top: 8px;
    padding-bottom: 2px;
}
.mainbox{
	padding-top: 14px;
}
.smmenubtnicon{
    color: white;
    padding: 7px 7px 0px;
    font-size: 27px;
    float: left;
}
.comptext{
    font-size: 21px;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    float: left;
    padding: 7px 25px 5px;
}
.ctrls{
    float: right;
    font-size: 24px;
    color: white;
}
.ctrlfonts{
    margin: 1.1rem 1.1rem 0rem -0.1rem;
    font-size: 18px;
}

.profile{
    color: white;
    font-size: 26px;
    margin-top: 25px;
    margin-right: 22px;
}
.maintenancebaruser-ex{
    font-size: 1rem;
    position: absolute;
    margin: 21px 0px 0px -40px;
    color: #ffbc00;
}
.maintenancebaruser{
  font-size: 2.7rem;
}
.iconarea{
  padding: 10px;
}
.textarea{
  padding: 10px;
}
.centerMain {
    margin-top: 93px;
}
 .fuelouter{
    width: 100%;
    padding: 0px!important;
    background-color: #e6e6e6;
    height: 20px;
    border-radius: 7px;
    margin-bottom: -4px !important;
}

.fuelinner{
    height: 20px;
    border-radius: 7px;
    padding: 0px!important;
}

.sm-dropdn{
    left: unset!important;
    top: 0.5rem!important;
}
.footer{
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    background-color: white;
    z-index: 99;
    left: 0;
    font-size: 0.8rem;
    padding: 0.8rem;
    border-top: 1px solid #b3b3b3;
    box-shadow: 0rem 0rem 0.4rem #464a4e;
}


/** Table */
.ReactTable.m-top-30{
    margin-top: 30px;
}
.ReactTable{
    .rt-tr-group{
        cursor: pointer;
    }
    .rt-thead .rt-th.-cursor-pointer{
        font-weight: bold;
        text-align: left;
    }
    .rt-thead.-header{
        padding: 0px 0;
        /* background-color: #dc1e38;
        color: #fff; */
    }
    .rt-th{
        text-align: left;
        font-weight: normal;
    }
}

.blu{
    color: #007bff;
}

.app-settings-links{
    min-width: 130px;
}
.marginRight.dropdown-menu.dropdown-menu-right.show{
    margin-right: -20px;
}
a.dropdown-item.left-align {
    padding: 2px !important;
}
.app-settings-links-customer{
    min-width: 60px;
}

.app-wrapper .backbtn{
    position: absolute;
    top: -20px;
    right: 5px;
    padding: 1px 6px !important;
    border-radius: 4px !important;
    color: #dc1e38;
    background: none;
    border: none;
    box-shadow: none;
    .backicon{
        float: left;
        margin-top: 4px;
        margin-right: 4px;
    }
}
/*.app-wrapper .backbtnrefresh{
    position: absolute;
    top: -10px;
    right: 5px;
    padding: 1px 6px !important;
    border-radius: 4px !important;
    color: #dc1e38;
    background: none;
    border: none;
    box-shadow: none;
    .backicon{
        float: left;
        margin-top: 4px;
        margin-right: 4px;
    }
}

/** Search Box - Equipments  */
.search-area{
    position: relative;
    .search-bar{
      border-radius: 20px;
      margin-top: 20px;
      padding-right: 35px;
    }
    .search-bar::-ms-clear {
        display: none;
    }
    .carat-position{
        top: 24px !important;
    }
    .common-srch-btn{
        background-color: transparent;
        border: 0px;
        position: absolute;
        right: 0px;
        bottom: 5px;
    }
    .common-x-btn{
        border: 0;
        background: transparent;
        position: absolute;
        right: 35px;
        bottom: 5px;
    }
    .search-card{
        margin-top: 5px;
        position: absolute;
        z-index: 1;
        width: 93%;
        .card-body{
            padding: 0;
            .no-padd-lr{
                padding-right: 0;
                padding-left: 0;
            }
        }
        .searched-img{
            width: 41px;
            height: 41px;
            max-width: fit-content;
        }
    }
    .member-options{
        position: absolute;
        right: 0;
    }
    .dropdown button{
        background: none;
        outline: none;
        box-shadow: none;
    }
    .dropdown-menu.show {
        right: 16px !important;
        top: 3px !important;
        left: auto !important;
    }
    .flexy-width{
        width: 200px;
    }
  }
  /** Map Css */
  .maploadr{
      position: absolute; top: 0; bottom: 0; left: 0; right: 0;
  }
.appheader{
    .alerttype{
        font-weight: bold;
    }
    .ERROR{
        color: #ffc107;
    }
    .THEFT{
        color: red;
    }
    .up-caret {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 8px 0px 8px;
        border-color: #ffffff transparent transparent transparent;
        display: inline-block;
        right: 12px;
        top: -11px;
        position: absolute;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .up-caret-sm{
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 8px 0px 8px;
        border-color: #ffffff transparent transparent transparent;
        display: inline-block;
        right: 5px;
        top: -11px;
        position: absolute;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .imageNoti{
        width: 1.9rem;
        padding: 0rem;
        cursor: pointer;
    }
    .noticover{
        margin: -0.5rem 1.0rem 0rem;
        float: right;
        cursor: pointer;
        .badge{
            background-color: #0000007a;
            border-radius: 25rem;
            margin: 0.24rem -1.4rem 0rem;
            position: absolute;
            font-size: 0.7rem;
            height: 23px;
            width: 23px;
        }
        .dropdown-menu{
            margin : 3.1rem -0.4rem 0 0;
            .scrollBox{
                max-height: 350px;
                overflow-y: scroll;
            }
        }
    }
    .alertcover{
        margin: -0.4rem 5px 0rem;
        float: right;
        cursor: pointer;
        .badge{
            background-color: #0000007a;
            border-radius: 25rem;
            margin: 0.1rem -1.4rem 0rem;
            position: absolute;
            font-size: 0.7rem;
            height: 23px;
            width: 23px;
        }
        .dropdown-menu{
            margin : 3.1rem -0.5rem 0 0;
            .scrollBox{
                max-height: 350px;
                overflow-y: scroll;
            }
        }
    }
    .wrinchtcover{
        .dropdown-menu{
            margin : 3.1rem 0 0 0;
        }
        svg{
            height: 22px;
            width: 22px;
            margin: 0.8rem 1rem 0rem;
        }
    }
    .maintainanceCover{
        left: auto !important;
    }
    .unread{
        background-color: #fdfdfd;
    }
    .unread:hover{
        background-color: #f3f1f1;
    }
    .listLineeq{
        font-size: 0.8rem;
        border-bottom: 1px solid #eae7e7;
        margin: 0.1rem 0.2rem 0.1rem 0.2rem;
        padding: 0.4rem 0.5rem 0.3rem 0.3rem;
        .expand{
            padding: 0rem 0rem 0rem 0rem;
            border-radius: 0px!important;
            svg{
                background-color: transparent;
                border-radius: 0px;
                width: 12px;
                padding: 0rem;
                margin-left: 0.3rem;
            }
        }
        .read-check svg{
            height: 19px !important;
        }
        svg{
            height: 29px;
            background-color: #0000009e;
            color: white;
            border-radius: 49px;
            padding: 5px;
            width: 29px;
            margin: 0px 6px -5px;
            float: left;
        }
        .text-secondary{
            font-style: italic;
        }
    }

    .w-16rem{
        width: 17rem;
        padding: 0rem 0rem 0rem 0.5rem;
    }
    .header-box{
        color: white;
        background-color: #dc1e38;
        margin-top: -5.4px;
        text-transform: uppercase;
        padding: 1rem 1rem 1rem;
    }
    .maintainanceCoverd{
        .header-box{
            margin-top: -6.4px;
        }
    }

    .ctrls{
        .imageNoti{
            width: 1.7rem;
            padding: 0rem;
            cursor: pointer;
        }
        .noticover{
            margin: 0.2rem 0.6rem 0rem 0.4rem;
            float: right;
            cursor: pointer;
            .scrollBox{
                max-height: 350px;
                overflow-y: scroll;
            }
        }
        .alertcover{
            margin: 0.2rem 0rem 0rem;
            float: right;
            cursor: pointer;
            .scrollBox{
                max-height: 350px;
                overflow-y: scroll;
            }
        }
        .wrinchtcover{
            svg{
                height: 20px;
                width: 20px;
                margin: 0.9rem 0.8rem 0rem 0.6rem;
            }
            .badge{
                background-color: #0000007a;
                border-radius: 25rem;
                margin: 0.1rem -1.4rem 0rem;
                position: absolute;
                font-size: 0.7rem;
                height: 23px;
                width: 23px;
            }
        }
        .listLineeq{
            .expand{
                padding: 0rem 0rem 0rem 0rem;
                border-radius: 0px!important;
                svg{
                    background-color: transparent;
                    border-radius: 0px;
                    width: 12px;
                    padding: 0rem;
                    margin-left: 0.3rem;
                }
            }
            svg{
                height: 15px;
                background-color: #0000009e;
                color: white;
                border-radius: 50px;
                margin: 0px 6px -3px;
                width: 15px;
            }
        }
        .allalert{
            position: absolute;
            margin-right: -94px;
            .up-caret-sm{
                right: 96px;
            }
        }
    }
}
.modal{
    .modal-header{
        .modal-close{
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    .error-alert-modal{
        .modal-header{
            background-color: red;
            padding: 15px;
            text-align: center;
            display: block;
            .modal-title{
                text-transform: uppercase;
                color: #fff;
            }
        }
        .modal-body{
            .arrow{
                position: absolute;
                top: 30%;
                color: #fff;
                background-color: #00000060;
                border-radius: 50%;
                z-index: -1;
            }
            .prev{ left: -30px;}
            .next{ right: -30px;}
            .margin-top-15{ margin-top: 15px;}
            .urgency-bar{
                margin-top: 20px;
                border-radius: 5px;
                display: inline-flex;
                .urgency-title{
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: bold;
                    border-radius: 0px 5px 5px 0px;
                    padding: 5px;
                }
                .urgency-type{
                    padding: 5px;
                    font-weight: bold;
                }
            }
            .urgency-bar.type1{
                border: 1px solid red;
                .urgency-title{
                    background-color: red;
                }
                .urgency-type{
                    color: red;
                }
            }
            .urgency-bar.type2{
                border: 1px solid #28A745;
                .urgency-title{
                    background-color: #28A745;
                }
                .urgency-type{
                    color: #28A745;
                }
            }
            .urgency-bar.type3{
                border: 1px solid #ffc107;
                .urgency-title{
                    background-color: #ffc107;
                }
                .urgency-type{
                    color: #ffc107;
                }
            }
        }
    }
}
.table{
    thead{
        tr, th{
            /* color: #fff;
            background-color: #dc1e38; */
        }
    }
}
.relative{ position: relative;}
.paginate {
    margin: 20px 0 0 0;
}
.pagination{
    li{
        border: 1px solid #dc1e38 !important;
        a{
            color: #464a4e;
        }
    }
    li.active {
        background-color: #dc1e38;
        a{
            color: #fff;
        }
    }
    li.active:hover {
        background-color: #dc1e38;
    }
}
.timesml{    font-size: 95%;}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

.map-legend {
    position: absolute;
    z-index: 1;
    top: 85px;
    background-color: white;
    padding: 10px;
    border: 1px #bdbdbd solid;
    border-radius: 5px;
    font-size: 12px;
    left:25px;

    &>div {
        position: relative;
        padding-left: 15px;
        margin-bottom: 3px;

        span {
            width: 10px;
            height: 10px;
            display: block;
            position: absolute;
            left: 0;
            top: 5px;

            img {
                vertical-align:top;
            }
        }
    }
}

@media (max-width: 670px) {
  .iconarea{
    width: 59px;
    margin: 0 auto;
    float: none!important;
  }
  .centerMain {
     margin-top: 50px!important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .map { height: 300px; }
  .xs-hidden{
    display: none;
  }
  .sm-inner-header,.navsec-text{
    display: inline;
  }
  .header{
      background-color: #dc1e38;
      min-height: 49px;
      width: 100%;
    }
    .sidebar{
        margin-top: -0.6rem!important;
    }
    .centerMain {
     margin-top: 50px!important;
    }
}

.info-windows{
    span{
        color: #ff0000;
        font-size: 13px;
        padding: 2px 0;
        display: inline-block;
    }
    strong{
        color: #0000ff;
        font-weight: 600;
        font-size: 13px;
        padding: 2px 0;
    
    }
}
.driving-status{
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    display: block;
    padding: 0 0 5px 0;
    text-decoration: underline;
}
 @media (min-width: 767px) and (max-width: 991px) {
    .sidebar{
      margin-top: 0.5rem;
    }
    .centerMain {
        margin-top: 50px!important;
    }
  }


@media (min-width: 320px) and (max-width: 480px) {

  .xs-hidden{
    display: none;
  }
  .sm-inner-header,.navsec-text{
    display: inline;
  }
  .header{
      background-color: #dc1e38;
      min-height: 49px;
      width: 100%;
    }
    .sidebar{
        margin-top: -0.6rem!important;
    }
    .centerMain {
        margin-top: 50px!important;
    }
}

.overlay-map{
    position: absolute;
    z-index: 9991;
    height: 100%;
    width: 100%;
}

.notification-wrapper {
    position: relative;
    display: inline-block;
  }
   
  .hover-text {
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    bottom: -90%; /* Adjust position as needed */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    transition: opacity 0.3s;
    white-space: nowrap;
    font-size:12px
  }
   
  .notification-wrapper:hover .hover-text {
    visibility: visible;
    opacity: 1;
  }

  .fixed-icon{
    max-width: 500px;
    //height: 450px;
    overflow-y: scroll;
    scrollbar-color: #868e96 transparent;
    scrollbar-width: thin;
    height: calc(100vh - 200px); 
  }
