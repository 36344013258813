.checkbox-group {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    label {
        display: inline-block;
        margin-bottom: 5px;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 2px;
        border: 1px solid #ccc;
        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
    input:checked ~ .checkmark {
        background-color: #dc1e38;
        border-color: #dc1e38;
        &:after {
            display: block;
        }
    }
    .checkmark:after {
        left: 6px;
        top: 1px;
        width: 7px;
        height: 14px;
        border: 1px solid #ffffff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .checkbtn{

        //position : absolute; 
        margin-left: 200px;                         
        //left : 320;
    }
}


.detailcovercustom {
    position: absolute;
    left: -40px;
    top:19px;
    
}

.input{
    left: 10px;
    margin-top :-11px;
}
.button-left{
    margin-Right:4px;
}
            
        
    
