.form-srch{
    background-color: #e0e0e0;
    border: 1px solid #c3bebe;
    padding: 15px 10px 30px;
}
.srch-btn{
    margin-top: 22px;
}
.repo .tabheading{
    font-size: 1.1em !important;
}
.centerMain{
    .repo {
        .nav{
            width: 65rem !important;
        }
    }
}

.errbg{
    background-color: #f7b2b2 !important;
}
.sucbg{
    background-color: #a8d0a8 !important;
}