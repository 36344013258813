.c-admin-wrapper {
    display: flex;
    justify-content: space-between;
    h3 {
        margin-bottom: 30px;
        padding: 0;
    }
    &-left {
        display: flex;
        align-items: flex-start;
        /* flex-direction: column; */
        .list {
            flex-grow: 1;
            display: flex;
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                li {
                    max-width: 40%;
                    display: flex;
                    //margin-right: auto;
                    justify-content: space-around;
                    flex-direction: row;
                    margin-bottom: 30px;
                    margin-left:15px;
                    flex-basis: 300px;
                    
                    span {
                        &.icon {
                            height: auto;
                            width: 20%;
                        }
                    }
                    div {
                        margin-left: 10px;
                        max-width: 80%;
                        margin-bottom: 60px;
                        h5 {
                            color: #dc1e38;
                            margin-bottom: 15px;
                            text-transform: uppercase;
                            font-size: 16px;
                        }
                        p {
                            font-weight: 500;
                            margin-bottom: 20px;
                        }
                        a {
                            color: #dc1e38;
                            padding-bottom: 1px;
                            border-bottom: 2px solid #dc1e38;
                            position: relative;
                            &:hover, &:active {
                                text-decoration: none;
                            }
                            &:before, &:after {
                                content: '';
                                display: block;
                                width: 8px;
                                height: 8px;
                                position: absolute;
                                right: -13px;
                                bottom: 4px;
                            }
                            &:before {
                                border-top: 1px solid;
                                transform: rotate(45deg);
                            }
                            &:after {
                                border-bottom: 1px solid;
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }
            }
        }
    }

    &-right {
        h5 {
            color: #dc1e38;
            font-size: 15px;
            margin: 0;
            margin-top: 10px;
        }
        p {
            font-style: italic;
            font-size: 12px;
        }
        .equipment-alert, .new-registration,.contractManagement{
            border: 1px solid #f0f0f0;
            padding: 30px;
        }
        .new-registration, .contractManagement{
            margin-top: 20px;
        }
        .error-count, .theft-count {
            border: 1px solid #dc1e38;
            display: flex;
            justify-content: flex-end;
            font-size: 22px;
            font-weight: bold;
            color: #dc1e38;
            img {
                width: 30px;
                height: 30px;
                align-self: center;
                margin-right: 20px;
            }
            & span {
                background-color: #dc1e38;
                padding: 5px 10px;
                color: #fff;
                width: 50px;
                text-align: center;
            }
            & div {
                align-self: center;
                margin-right: 10px;
            }
        }
        .contract-count{
            border: 1px solid #dc1e38;
            display: flex;
            justify-content: flex-end;
            font-size: 17px;
            font-weight: bold;
            color: #dc1e38;
            & span {
                background-color: #dc1e38;
                padding: 5px 10px;
                color: #fff;
                width: 50px;
                text-align: center;
            }
            & div {
                align-self: center;
                margin-right: 10px;
            }
        }
        .error-count, .contract-count {
            margin-bottom: 20px;
        }
        .bottom {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            p {
                font-size: 14px;
                font-style: normal;
            }
            .btn-register {
                margin-top: 30px;
                background-color: #dc1e38;
                padding: 5px 28px;
                border-radius: 20px;
                border: 1px solid transparent;
                color:#fff;
                align-self: flex-end;
            }
        }
    }
}