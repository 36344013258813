.repo{
    .nav{
        width: 50rem !important;
    }
}
.reports{
    .react-datepicker__navigation--previous {
        border-right-color: #dc1e38;
    }
    .react-datepicker__navigation--next{
        border-left-color: #dc1e38;
    }
    .coverinput{
        width: 100%;
        height: 27px;
        position: absolute;
    }
    .tab-content{
        margin-top: -9px;
        border-top: 1px solid #b3b7bb;
        padding: 1rem;
    }
    .inputLabel{
        font-weight: bold;
        width: 12.8rem;
    }
    .inputboxLabel{
        margin-right: 3rem;
        margin-left: 0.9rem;
    }
    .selectwrapper{
        display: inline-block;
    }
    .nav{
        width: 38rem;
        margin: 0 auto;
        border: 0px;
        .nav-item{
            .active{
                div:first-child{
                    border-bottom: 3px solid #dc1e38;
                    padding-bottom: 20px;
                }
                div{
                    svg{
                         color: #dc1e38;
                    }
                    .tabheading{
                        color: #dc1e38;
                    }
                } 
            }
            .nav-link{
                text-align: center;
                border-color: white;
                padding: 0.5rem 0rem;
                svg{
                    font-size: 5.4rem;
                    color: #424040;
                }
                .tabheading{
                    color: #424040;
                    font-size: 1.4rem;
                    text-transform: uppercase;
                    font-weight: bolder;
                }
                div{
                    color:grey;
                    width: 16rem;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .table-responsive{
      	display: block!important;
    }
    .reports .nav .nav-item .nav-link .tabheading {
        font-size: 0.9rem;
    }
    .reports .nav {
        width: 23rem;
    }
    .reports .nav .nav-item .nav-link div {
        width: 10rem;
    }
}
@media (min-width: 767px){
    .table-responsive {
        display: table!important;
    }
}