.toptabingbar{
    width: 100%;
    margin-bottom: 22px;
    border-bottom: 2px solid #e8e8e8;
    div{
        margin-bottom: -0.9px;
        font-size: 18px;
        float: left;
        color: #464a4e;
        padding-right: 18px;
        padding-left: 18px;
        font-weight: 500;
        border-bottom: 1px solid #9c9b9b;
        box-shadow: 0px 1px 0px 0px #ccc;
        padding-bottom: 8px;
        cursor: pointer;
    }
    .active{
        border-bottom: 1px solid #dc1e38;
        color: #dc1e38;
    }
}