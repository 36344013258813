.full-col{ width: 100%;}
.highlighted { font-weight: bold; }
.pull-right{ float: right;}
.no-padded{ padding-top: 0 !important; padding-bottom: 0 !important;}
.area-bordered { border: 1px solid #c1c1c1; padding: 6px 0; height: 70px;}
.area-filled{ background-color: #c1c1c1; padding: 6px 0; height: 70px;}
.tracker-img-pos img{ border-radius: 50%;    width: 48px; height: 48px;}
/* .tracker-img-pos{margin-top: 5px;}
.tracker-info-pos{margin-top: 3px;} */
.tracker-perftxt-pos{margin: 4px;}
.tracker-perf-pos span.pull-right{margin-right: 10px;}
/** Reporting & Charting Area */
.map-chart-area {margin-top: 20px;}
.report-area{ margin-top: 20px;  border: 1px solid #c1c1c1;width: 100%;letter-spacing:0; }
.work-report-area table{border: 1px solid #ddd; margin-bottom:0;font-size: 14px; }
.work-report-area .table td {vertical-align: middle;}
.greyed{ background-color: #dc1e38;}
.cal-date{ font-size: 80px; margin-bottom: 0; color:#fff; }
.cal-month{ font-size: 20px; text-transform: uppercase; margin-bottom: 0; color:#fff; }
.cal-year{ font-size: 20px; margin-bottom: 0; color:#fff; }
.map-chart-area{
    .react-datepicker{ width: 100%; padding: 9px; border: none !important; }
    .react-datepicker__header{ background-color: #fff !important;}
    .react-datepicker__month-container{ width: 100%; }
    .block{ width: 15px; height: 15px; margin: 0px 5px; display: inline-block;}
    .red{ background-color: red;}
    .yellow{ background-color: yellow;}
 }
span.route-map-btn { background-color: #50b259; border-radius: 15px; color: #fff; padding: 6px 10px; cursor: pointer; text-transform: uppercase;}
.react-datepicker__day--today { color: #dc1e38; text-decoration: underline; background-color: transparent; }
.react-datepicker__day--today:hover{ background-color: transparent; }
.react-datepicker__day--highlighted {
    background-color: yellow !important;
    color: #777;
}
.react-datepicker__day--selected {
    background-color: #216ba5 !important;
    color: #fff;
}
.m-top-15{
    margin-top: 15px;
}
.no-edit{background: none !important; border: none !important; cursor: default;}
path{pointer-events: none !important;}
.no-padded{    padding-top: 0; padding-bottom: 0;}
.o-auto{ overflow: auto}

/*Machine-Chart-Box*/
.Machine-Chart-Box{
    min-height: 130px;
    padding: 10px;
    border: 1px solid #c1c1c1;
    .border-map-box {
        border:1px gray solid;
        position: relative;
        height: 40px;
        box-sizing: border-box;


        .map-column-group {
            position: absolute;
            top:0;
            height: 38px;
            background-color: red;
            // border: 1px red solid;
        }
    }

    
    .timer {
        margin-top:10px;
        position: relative;
        .timer-column {
                float: left;
                text-align: center;
                position: relative;
                padding-left: 4px;
                
        }
        .line {
            height: 22px;
            width: 2px;
            background: #dc1e38;
            position: relative;
            display: inline-block;
            z-index: 2;
            b{
                position: absolute;
            }
            // margin: auto;
        }

        .box-text{
            position: absolute;
            top: 22px;
            border: 1px #bdb9b9 solid;
            padding: 1px 2px;
            font-size: 10px;
            width: 35px;
            margin-left: -17.5px;

            // display: block;
            // border: 1px #c1c1c1 solid;
            // padding: 0px 15px;
            // font-size: 11px;
            // margin-top: 20px;
            // margin-left: -26px;
            // width: 56px;
            // color: #000000;
            // background-color: #ffffff;
        }

        &::after{
            position: absolute;
            content: '';
            background-color: #00b0f0;
            width: auto;
            left: 22px;
            right: 22px;
            height: 3px;
            top: 5px;
            z-index: 1;
        }
    }
}
.time-intervel{
    background-color: #000000;
    width: 1px;
}
.Highspeed-Hours-Bg{
    background-color: #e47900;
}
.PTO-running-Time-Bg{
    background-color: #4472c4;
}
.Idleing-Time-Bg{
    background-color: #70ad47;
}
.Running-Time-Bg{
    background-color: #f4b183;
}
// .text-small {
//     font-size: 10px;
// }
.h-40 {
    height:38px;
}
.HSH span {
    background-color: #27aad4;
    height: 10px;
    width: 10px;
    line-height: 10px;
    border: 1px;
    display: inline-block;
    border-radius: 10px;
    margin-right: 5px;
    vertical-align: middle;
}
.PTH span {
    background-color: #4373d5;
    height: 10px;
    width: 10px;
    line-height: 10px;
    border: 1px;
    display: inline-block;
    border-radius: 10px;
    margin-right: 5px;
    vertical-align: middle;
    margin-top: 0px;
}
.OTH span {
    background-color: blue;
    height: 10px;
    width: 10px;
    line-height: 10px;
    border: 1px;
    display: inline-block;
    border-radius: 10px;
    margin-right: 5px;
    vertical-align: middle;
    margin-top: 6px;
}
.ITH span {
    background-color: #f4b27e;
    height: 10px;
    width: 10px;
    line-height: 10px;
    border: 1px;
    display: inline-block;
    border-radius: 10px;
    margin-right: 5px;
    vertical-align: middle;
}
.RTH span {
    background-color: #72a955;
    height: 10px;
    width: 10px;
    line-height: 10px;
    border: 1px;
    display: inline-block;
    border-radius: 10px;
    margin-right: 5px;
    vertical-align: middle;
}
.HSH strong,.PTH strong,.ITH strong,.RTH strong{
    font-size: 12px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .area-filled, .area-bordered{
        padding-left: 15px !important;
        height: auto;
    }
    .mobile-map{
        height: 300px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .mobile-map{
        height: 300px;
    }
}