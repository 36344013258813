.sp-equipment-detail {
    .fix-h {
        height: 120px;
        width: 100%;
    }

    .grey-title {
        text-transform: uppercase;
        background-color: #e6e6e6;
        border: 1px solid #cecaca;
        padding: 2px 10px;
    }

    .eqdetails {
        .eqdetailbox {
            .detailcover {
                margin-bottom: 10px;

                /*.float-right{
                    width: 135px;
                }*/
                .auto-width {
                    width: auto;
                }

                .pinned {
                    padding: 4px 15px;
                    display: inline-block;
                    background: gray;
                    color: #fff;
                }
            }
        }
    }

    .toprow {
        margin-bottom: 30px;

        .tractorDetail {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            color: #fff
        }
    }

    .relative {
        position: relative;

        .route-map-btn {
            position: absolute;
            right: 60px;
            bottom: 5px;
        }
    }


}

.calLogo {
    //position: absolute;
    border: 0;
    display: inline-flex;
    top: 30px;
    right: 1px;
    font-size: 1.5rem;
    background-color: white;
    padding: 0;
}

.calLogoContract {
    position: absolute;
    border: 0;
    display: inline-flex;
    top: 9px;
    right: 8px;
    font-size: 1.5rem;
    background-color: white;
    padding: 0;
}

.calLogoContractTractor {

    border: 0;
    display: inline-flex;
    top: 9px;
    right: 8px;
    font-size: 1.5rem;
    background-color: white;
    padding: 0;
    transform: translateX(-28px);
}

.react-datepicker__time-list {
    padding: 0;
    padding-right: 0px !important;
}

/* .tabledashboard thead tr th {
    .firstRow {
        border : '10px solid #e8e8e8';
    }
} */

.tabledashboard {
    overflow-y: scroll;
    max-height: 500px;
}

.tableFixHead thead tr {
    display: block;
}


.tableFixHead tbody {
    display: block;
    height: 500px;
    overflow: auto;
}

.modalClass {
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        max-height: 700px;
        overflow-y: auto
    }

    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
        max-height: 500px;
        max-width: 700px;
    }

    .modal-sm {
        max-width: 300px;
    }
}

.checkbox-group {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    label {
        display: inline-block;
        margin-bottom: 5px;
        cursor: pointer;
        line-height: 30px;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 2px;
        border: 1px solid #ccc;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    &:hover input~.checkmark {
        background-color: #ccc;
    }

    input:checked~.checkmark {
        background-color: #dc1e38;
        border-color: #dc1e38;

        &:after {
            display: block;
        }
    }

    .checkmark:after {
        left: 6px;
        width: 7px;
        height: 14px;
        border: 1px solid #ffffff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.equipmentInfo {

    .radioButton-group {
        display: inline-block;
        position: relative;
        margin-right: 30px;

        label {
            margin: 0;
            padding-left: 15px;
            cursor: pointer;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            margin: 0;

            &:checked~.checkmark {
                background-color: #dc1e38;

                &:after {
                    display: block;
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 18px;
            width: 18px;
            background-color: #d2d2d2;
            border-radius: 50%;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }

            &:after {
                top: 5px;
                left: 5px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: white;
            }
        }

        &:hover input~.checkmark {
            background-color: #bdbdbd;
        }

        &:hover input:checked~.checkmark {
            background-color: #dc1e38;
        }
    }

    .custom-radio .custom-control-label::before {
        border-radius: 50%;
        background-color: #fff;
    }

}

div[disabled] {
    pointer-events: none;
    opacity: 0.7;
}

.eqService {
    td:nth-child(5) {
        width: 12%;
    }

    td:nth-child(6) {
        width: 18%;
    }

}

.save {
    margin-left: auto;
    margin-right: 30px;
}

.sp-topRow {
    .exclamationt {
        height: 20px;
    }
}

.calculated {
    background-color: white;
    border: none;
    padding: 0.65rem 0.8rem;
    line-height: 1.25;
    font-size: 1rem;
    //margin-left: -96px;
    font-size: medium;
    width: 100px
}

.operator {
    margin-top: 26px;
}

.selected {
    background-color: #e0e0e0;
    border-color: #cbc2c2;
    border-block-width: 1px;
    border-right-width: 1px;
    //color:black;
    font-weight: bold;
}

.unselected {
    background-color: white;
    border-color: #cbc2c2;
    border-block-width: 1px;
    border-right-width: 1px;
    // color:black;
}
.searchcomponent{
    img{
        width: 1.9rem;
        margin: 0.1rem 0.7rem 0rem 0rem;
    }
    .react-bootstrap-daterangepicker-container{
        float: left;
        img{
            width: 1.9rem;
            margin: 0.1rem 0.7rem 0rem 0rem;
        }
    }
    button{
        color: black;
        font-weight: 600;
        svg{
            margin: 0rem 1rem -0.2rem 0rem;
        }
    }
    .btnsearch{
        margin: -0.2rem 0rem -0rem 1rem;
        font-size: 1.7rem;
        border: 0px;
        background-color: white;
    }
    .filtertype{
        width: 182px;
        text-align: left;
        .filtericon{
            font-size: 19px;
        }
        img {
            width: 19px;
            margin: -3px -1px 0px;
        }
    }
}

.containerbox {
    position: relative;
    display: inline-block;
}

.select {
    margin-bottom: -10px;
    width: 115px; 
}
.selectType {
    margin-bottom: -5px;
    width: 90px;
}
select.form-control:not([size]):not([multiple]) {
    height: 0;
    padding: 0 0;
}

.yearmonth {
    padding-bottom: 5px;
    padding-top: 5px;
}

.tooltip1 {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black;
  }
  
  .tooltip1 .tooltiptext {
    visibility: hidden;
    width: 170px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltip1 .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 35%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip1:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .tooltipicon {
    background-color: gray;
    border-radius: 50%;
    padding: 0.1em 0.2em;
    color: white;
    display: inline-block;
    font-size: 10px;
    transition: font-size 0.2s;
  }

  .tooltipicon:hover{
    font-size:12px;
  }

p.ridge {
    border-style: ridge;
}
