.loginView{
    .box-login{
        max-width: 571px;
        margin: 0 auto;
        margin-top: 10%
    }
    .topheader{
        background-color: #dc1e38!important;
        height: 75px;
    }
    .topheader{
        img{
            margin-top: 20px;
            width: 312px;
            margin-left: 20px;
        }
    }
    .loginforms{
        max-width: 247px;
        margin: 0 auto;
        .text-red {
            &.admin {
                pointer-events: none;
            }
        }
    }
    .myloginbtn{
        width: 100%;
        background-color: #dc1e38!important;
        text-transform: uppercase;
    }
    .form-check{
        padding: 0px 0px 12px !important;
    }
}
.radioButton-group {
    display: inline-block;
    position: relative;
    margin-right: 30px;
    label {
        margin: 0;
        padding-left: 30px;
        cursor: pointer;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        margin:0;
        &:checked ~ .checkmark {
            background-color: #dc1e38;
            &:after {
                display: block;
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #d2d2d2;
        border-radius: 50%;
        &:after {
            content: "";
            position: absolute;
            display: none;
        }
        &:after {
            top: 5px;
           left: 5px;
           width: 8px;
           height: 8px;
           border-radius: 50%;
           background: white;
        }
    }
    &:hover input ~ .checkmark {
        background-color: #bdbdbd;
    }
    &:hover input:checked ~ .checkmark {
        background-color: #dc1e38;
    }
}
.pointer-event{
    pointer-events: none;
}

.admin.fp, .fp:hover{
    color: #dc1e38 !important;
}