span.role-title {
    padding: 5px 10px;
    border: 1px dashed red;
    margin-right: 5px;
    margin-top: 5px;
    background-color: rgba(255, 0, 0, 0.14901960784313725);
    display: inline-block;
}

.btn.role-title {
    padding: 5px 20px;
    border: 1px dashed red !important;
    margin-right: 5px;
    background-color: rgba(255, 0, 0, 0.14902) !important;
    display: inline-block;
    margin-top: 0px !important;
    color: #000 !important;
}

.role-title.btn.active {
    background-color: #dc1e38 !important;
    color: #fff !important;
    /* border: 1px dashed #fff; */
}
.tabledashboard.role-row button  {
    margin: 0;
}

.tabledashboard.role-row button.active
{    
    background-color: #dc1e38;
    color: white;
}
.link-role {
    height: 35px;
}
.pointer-default {
    cursor: default !important;
}
.compareRole{
    margin-right: 10px;
}
.compareRoleRow .table td {
    padding: 0.1rem ;
    padding-top: 1px ;
    padding-left: 4px ;
    padding-bottom: 1px ;
    height : 10px;
}
.role-list {
    b {
        text-transform: capitalize;
    }
    .space-v {
        margin-bottom: 10px;
    }
    tr {
        td {
            button {
                margin-top: 0 !important;
            }
        }
    }
}

.uprcase{ text-transform: uppercase;}