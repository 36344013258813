.tabledashboard{
	border: solid 1px #eeeeee;
    background-color: white!important;
}
.lock {
  position: relative;
}
.lock:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
}
.lock select, .lock input, .lock textarea{
  background-color: #dadada;
}
.datasheetsys{
  .editbtn{
    border: 0px currentColor;
    border-image: none;
    background-color: rgba(0, 0, 0, 0);
  }
  .inprogress{
    width: 25px;
    margin-left: 13px;
  }
  .completed{
    width: 20px;
  }
  .notstart{
    width: 20px;
    margin-left: 13px;
  }
}
.ssadj{
  width: 3rem;
  margin-left: -3px;
}
.modal-content{
  .selectwrapper{
    select, input{
      padding: 0px;
    }
  }
  .react-datepicker-wrapper{
    width: 100%;
    .react-datepicker__input-container{
      width: 100%;
      input{
        border: 1px solid #ced4da;
        width: 100%;
        padding: 7px;
      }
    }
  }
  .react-datepicker__time-list{
      padding: 0;
      padding-right: 0px!important;
  }
}

.tabledashboard {
    .width-edit{
      width: 224px;
    }
    thead tr th{
        font-size: 0.8em;
    }
    tbody{
        tr{
            cursor: pointer;
            td{
                .statuse{
                    width: 2.5rem;
                }
                .faicon{
                  font-size: 1.4rem;
                  margin: 0rem 0.3rem 0rem;
                }
                .red{
                  color: darkred;
                }
                .green{
                  color: green;
                }
                .statuse{
                    width: 2.5rem;
                }
                .noneditable{
                  background-color: white;
                  color: black;
                  border: 0;
                  padding: 2px 3px 2px;
                }
                .edit{
                  background-color: white;
                  padding: 8px 3px 7px!important;
                }
                input{
                  background-color: white;
                  padding: 8px 3px 7px;
                }
                .react-datepicker__input-container{
                  input{
                      padding: 5px 1px 6px;
                      border: 1px solid #ced4da;
                  }
                }
                .selectwrapper{
                  select{
                    padding: 0rem;
                  }
                }
                .react-datepicker__time-list{
                    padding: 0;
                    padding-right: 0px!important;
                }
            }
        }

    } 
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .xs-hidden{
        display: none;
    }
    .xs-show{
        display: inline;
      }
    .xs-show-table{
        display: table-cell;
      }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .xs-hidden{
    display: none;
  }
  .xs-show{
    display: inline;
  }
  .xs-show-table{
    display: table-cell;
  }
}

