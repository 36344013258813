.no-padd-left{ padding-left: 0 !important;}
.eqalertcontainer{
    .box-img{
        width: 25px;
        margin-right: 5px;
        margin-top: -3px;
    }
    .clearfix{
        clear: both;
    }
    
    .notificationcomponent{
        margin: 0rem 1.7rem 0rem 0rem;
        .notibtntype{
            padding: 0.3rem 1rem 0.3rem 1rem;
            svg{
                margin: 0rem 1rem -0.2rem 0rem;
            }
        }
        .form-check-input {
            position: relative;
            margin-left: 0rem;
        }
        .opcover{
            padding: 0rem 1rem 0rem;
        }
        .inputf{
            padding: 0px;
            font-weight: 600;
            color: black;
        }
        .inputfd{
            padding: 0px;
            padding-bottom: 0.5rem;
        }
    }

    .searchcomponent{
        img{
            width: 1.9rem;
            margin: 0.1rem 0.7rem 0rem 0rem;
        }
        .react-bootstrap-daterangepicker-container{
            float: left;
            img{
                width: 1.9rem;
                margin: 0.1rem 0.7rem 0rem 0rem;
            }
        }
        button{
            color: black;
            font-weight: 600;
            svg{
                margin: 0rem 1rem -0.2rem 0rem;
            }
        }
        .btnsearch{
            margin: -0.2rem 0rem -0rem 1rem;
            font-size: 1.7rem;
            border: 0px;
            background-color: white;
        }
        .filtertype{
            width: 182px;
            text-align: left;
            .filtericon{
                font-size: 19px;
            }
            img {
                width: 19px;
                margin: -3px -1px 0px;
            }
        }
    }
    .databoxcomponent{
        .box{
            font-size: 1.15rem;
            font-weight: 500;
            border: 0.15rem solid;
            padding: 0.7rem 0.3rem 0.7rem 0.3rem;
            .countholder{
                color: white;
                position: absolute;
                right: 4px;
                top: 4px;
                padding: 0.8rem;
            }
        }
        @media (max-width: 480px) and (min-width: 320px){
            .box{
                font-size: 1rem;
            }
        }
        .boxred{
            color:white;
            background-color:#dc1e38;
            border-color: #dc1e38;
        }
        .boxcountred{
            color: #dc1e38;
             .countholder{
                background-color:#dc1e38;
            }
        }
        .boxcountred.greyed{
            color: #000;
            border-color: #c7c7c7;
            background-color: #c7c7c7;
             .countholder{
                background-color:#c7c7c7;
                color:#000;
            }
        }
        .boxgreen{
            color: #28a745;
             .countholder{
                background-color:#28a745;
            }
        }
        .boxyellow{
            color:#ffc107;
             .countholder{
                background-color: #ffc107;
            }
        }
        .boxprimary{
            color: #464a4e;
            .countholder{
                background-color: #464a4e;
            }
        }
        @media (min-width: 992px){
            .col-lg-2-sv {
                max-width: 15.1%;
            }
        }
    }
}

.eqalertdetailcontainer{
    .table-wrapper{
        overflow: auto;
        height: 250px;
    }
    .callstatus{
        position: absolute;
        border: 1px solid;
        padding: 0.2rem 0.6rem 0.2rem;
        margin: -1.6rem 1rem 0rem;
        right: -15px;
        top: 21px; 
        img{
            width: 18px;
            margin-top: -3px;
        }
    }

    .updatesupportinfo{
        padding: 13px;
         .addbtn{
            position: absolute;
            right: 17px;
            top: 27px;
        }
        .dropdown{
            button{
                color: black;
                font-weight: 600;
                border-radius: 0px!important;
                padding-left: 8px!important;
                padding-right: 8px!important;
                svg{
                    margin: 0rem 0.4rem -0.2rem 0rem;
                }
                img{
                    width: 25px;
                }
            }
        }
        textarea{
            resize: none;
            width: 88%;
            padding-right: 15px;
            padding-left: 15px;
            height: 33px;
        }
    }
    
    .filtertype{
        width: 182px;
        text-align: left;
        .filtericon{
            font-size: 19px;
        }
        img {
            width: 19px;
            margin: -1px 4px 0px 0px;
        }
    }
    .inprogress, .callscheduled, .completed{
        color: #488248;
        background-color: #e7f7e7;
        img{
            width: 22px;
        }
    }
    .notstarted{
        color: #868e96;
        background-color: #f8f9fa;
    }
    .smallurgency{
        margin-left: 0 !important;
        font-size: 16px !important;
    }
    .urgencyColor1{
        color: #dc1e38;
        font-size: 20px;
        padding-top: 4px;
        margin-left: 9px;
        margin-right: -5px;
    }
    .urgencyColor2{
        color: #28a745;
        font-size: 20px;
        padding-top: 4px;
        margin-left: 9px;
        margin-right: -5px;
    }
    .urgencyColor3{
        color: #ffc107;
        font-size: 20px;
        padding-top: 4px;
        margin-left: 9px;
        margin-right: -5px;
    }
    .eqdetailbox{
        .detailcover{
            clear: both;
            .min-desc{
                width: 330px;
            }
            div:first-child{
                font-weight: bold;
                width: 30%;
            }
            div{
                float: left;
                margin: 0.4rem;
            }
        }
    }
    .supportinfo{
        text-transform: uppercase;
        font-weight: bold;
        color: #dc1e38;
        font-size: 18px;
        border-bottom: 1px solid rgba(220, 30, 56, 0.2784313725490196);
    }
}

.cancelbtn{
    text-transform: uppercase;
}
