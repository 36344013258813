.equipmentdetail{
    .viewall{
        margin-top: -18px;
        padding: 1px 3px 1px 3px;
        position: absolute;
        right: 15px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.6588235294117647);
        border-radius: 3px!important;
        svg{
            margin: 0px 5px -2px;
        }
    }
    .exclamationt{
        width: 1.7rem;
        margin-right: 0.8rem;
    }
    .toprow{
        padding: 2rem 0px 0.6rem;
    }
    .imagetractor{
        width: 229px;
        height: 195px;
        padding: 4px 4px 4px;
    }
    .inlinehead{
        font-weight: bolder;
        text-transform: uppercase;
        margin-left: 5px;
    }
    .tractorDetail{
        color: white;
        background-color: rgba(0, 0, 0, 0.47843137254901963);
        text-align: center;
        padding: 5px 0px 7px;
        font-size: 1.2rem;
        position: relative;
        width: 200px;
        margin: 0 auto;
        margin-top: -38px;
        margin-bottom: 22px;
    }
    .eqdetailbox{
        .detailcover{
            clear: both;
            time{
                text-transform: capitalize !important;
                font-weight: normal !important;
            }
            .editableDiv{
                div{
                    width: 122px;
                }
            }
            :first-child{
                width: 159px;
                text-transform: uppercase;
                font-weight: 500;
            }
            div{
                float: left;
                padding: 9px;
                .mappointer{
                    cursor:pointer;
                    img{
                        width: 30px;
                    }
                }
            }
        }
    }
    .alertsettingbox{
        width: 100%;
        border: 0.1rem solid #908c8c;
        border-radius: 2rem;
        margin: 3rem 2rem 3rem;
        padding: 0.3rem 0.3rem 0rem;
        .text{
            width: 164px;
            height: 43px;
            color: #dd1e39;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            float: left;
            img{
                width: 43px;
                float: left;
            }
            div{
                width: 121px;
                padding: 3px 9px 0px;
                line-height: 1.3rem;
                float: left;
            }
        }
        .detailcover{
            padding: 0.5rem;
            float: left;
            margin-top: 0.21rem;
            img{
                width: 1.9rem;
            }
            svg{
                font-size: 1.8rem;
                color: #3a3a3a;
                cursor: initial;
            }
            span:first-child{
                font-weight: bold;
            }
            span{
                color: grey;
            }
            .optime{
                vertical-align: 0.6rem;
            }
            
        }
        .time{
            div{
                background-color: #d2cfcf;
                border-radius: 5rem;
                padding: 3px 16px 3px;
                font-size: 0.8rem;
            }
        }
    }
}
.equipment-detail-modal-body{
    height: 350px;
    overflow: auto;
    .exclamationt{
        width: 1.5rem;
        margin-right: 0.8rem;
        margin-top: -0.3rem;
    }
    .alert{
        padding: 3px;
        font-size: 13px;
    }
}

.clocker{
    margin-right: 3px;
}
.ico-fix{
    margin-top: 2px;
    float: left;
}
.errorMessage{
    color: #dc1e38;
}