.member-machine-info, .member-personal-info,.member-contract-info{
    position: relative;
    .machine-asset{
        margin-top: 10px;
    }
    .machine-button{
        margin-top: 40px;
    }
    .overlayer{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.08);
    }
}
.member-personal-info,.margin-up{
    margin-top: 30px;
}
.member-contract-info.margin-up{
    margin-top: 30px;
}
.member-contract-info.margin-up.contract{
    margin-top: 30px;
}

.sub-heading{
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #dc1e38;
    width: 100%;
}
.u_l{
    text-decoration: underline;
}
.m-top-30{
    margin-top: 30px;
}
.owner-mgmt {
    overflow: auto;
}

tr:hover td {
    background: #F5F5F5 !important;
}

tr:hover td {
    color: #dc1e38 !important;
}