.c-location-information {
    display: flex;
    flex-direction: column;
    position: relative;
    @media (min-width:768px) {
        height: auto;
    }
    .conatiner {
        .multiselect {
            display: block;
            border: 1px solid #f0f0f0;
            padding: 8px 14px 0px;
            padding-right: 0;
            position: relative;
            margin-bottom: 25px;
            p{
                font-weight: bold;
                cursor:pointer;
                margin-bottom: 8px;
                svg{
                    float: right;
                    margin-right: 11px;
                    margin-top: 3px;
                }
            }
            .form-group {
                .custom-control {
                    padding-bottom: 5px;
                }
            }
            .checkbox-group {
                display: block;
                position: relative;
                padding-left: 30px;
                margin-bottom: 5px;
                font-size: 16px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                label {
                    display: inline-block;
                    margin-bottom: 5px;
                    cursor: pointer;
                }
                .checkmark {
                    position: absolute;
                    top: 3px;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    border-radius: 2px;
                    border: 1px solid #ccc;
                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                }
                &:hover input ~ .checkmark {
                    background-color: #ccc;
                }
                input:checked ~ .checkmark {
                    background-color: #dc1e38;
                    border-color: #dc1e38;
                    &:after {
                        display: block;
                    }
                }
                .checkmark:after {
                    left: 6px;
                    top: 1px;
                    width: 7px;
                    height: 14px;
                    border: 1px solid #ffffff;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
            &-dropdown {
                max-height: 100px;
                overflow-y: scroll;
            }
            .cta{
                // margin: -11px;
                margin-bottom: 15px;
                display: flex;
                justify-content: flex-start;
                padding-right: 25px;
                input {
                    border-radius: 20px;
                    margin-right: 10px;
                }
                button {
                    &:last-child{
                        margin: 3px 6px 0px;
                    }
                    &:first-child{
                        margin: 2px 3px 0px;
                        width: 100%;
                        padding-left: 3px;
                        padding-right: 3px;
                    }
                }
                @media (min-width:768px) {
                    div{
                        padding: 1px;
                    }
                    div{
                        padding: 1px;
                    }
                }
            }
            .toggle {
                position: absolute;
                top: 38px;
                z-index: 1;
                left: 0;
                right: 0;
                background: #fff;
                padding-top: 20px;
                padding-left: 20px;
                &.show {
                    border: 1px solid #f0f0f0;
                }
            }
        }
        .tractor-info{
            display: flex;
            padding: 5px;
            .left{
                margin-right: 20px;
                p {
                    margin: 0;
                    text-align: center;
                    background: rgba(1,1,1,0.8);
                    color: white;
                    padding: 5px 0;
                }
            }
            .right {
                display: flex;
                ul {
                    margin: 0;
                    padding: 0;
                    align-self: center;
                    li {
                        list-style-type: none;
                        padding: 5px;
                        display: flex;
                        color: #333;
                        align-items: center;
                        span {
                            font-weight: bold;
                            text-transform: uppercase;
                            width: 150px;
                        }
                    }
                }
            }
        }
        .tractor-status {
            ul {
                display: flex;
                padding: 0;
                margin-top: 20px;
                li {
                    list-style-type: none;
                    //padding: 0px 11px 0px;
                    span {
                       // margin-left: 5px;
                    }
                }
            }
        }
        .btn {
            &.disabled {
                pointer-events: none;
                cursor: no-drop;
            }
        }
    }
}
.hidden {
    display:none;
}
 .show{
    display:block;
}

.checkmark.active {
    background-color: #dc1e38 !important;
    border-color: #dc1e38!important;
}