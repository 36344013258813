.tabledashboard{
	border: solid 1px #eeeeee;
    background-color: white!important;
}
.tooltip1 {
  position: relative;
  display: inline-block;
  //border-bottom: 1px dotted black;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.equipmentalertlist{
  .callScheduled {
      width: 20px;
      margin-left: 15px;
  }
  .urgencyColor1{
    color: #dc1e38;
    font-size: 20px;
    padding-top: 4px;
    margin-left: 9px;
    margin-right: -5px;
  }
  .urgencyColor2{
    color: #28a745;
    font-size: 20px;
    padding-top: 4px;
    margin-left: 9px;
    margin-right: -5px;
  }
  .urgencyColor3{
    color: #ffc107;
    font-size: 20px;
    padding-top: 4px;
    margin-left: 9px;
    margin-right: -5px;
  }
  .widthmore{
    width: 165px;
    img{
      margin-right: 3px;
    }
    button{
      margin-top: 1px;
    }
  }
  .inprogress{
    width: 25px;
    margin-left: 13px;
  }
  .completed{
    width: 20px;
  }
  .notstart{
    width: 20px;
    margin-left: 13px;
  }
  .callout{
    p{
      margin-bottom: 1.4px;
      text-align: center;
    }
  }
}

.tabledashboard {
    thead tr th{
        font-size: 0.8em;
        svg{
          pointer-events: none;
        }
    }
    tbody{
        tr{
            cursor: pointer;
            td{
                .statuse{
                    width: 2.5rem;
                }
                .urgencyColor1{
                  margin: 4px;
                }
                .urgencyColor2{
                  margin: 4px;
                }
                .urgencyColor3{
                  margin: 4px;
                }
            }
        }

    } 
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .xs-hidden{
        display: none;
    }
    .xs-show{
        display: inline;
      }
    .xs-show-table{
        display: table-cell;
      }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .xs-hidden{
    display: none;
  }
  .xs-show{
    display: inline;
  }
  .xs-show-table{
    display: table-cell;
  }
}

