.tabledashboard{
	border: solid 1px #eeeeee;
    background-color: white!important;
}

.noPadding {
  padding-right: 0px !important;
  padding-left: 0px !important; 
}
.ssa{
 /*  .innerServiceTable{
    padding-left: 0px;
    width: 50px !important;
    overflow-x: auto !important;
    overflow-y: hidden;
  } */

  .inprogress{
    width: 15px;
    margin-left: 13px;
  }
  .completed{
    width: 15px;
  }
  .notstart{
    width: 15px;
    margin-left: 13px;
  }
  .callout{
    p{
      margin-bottom: 1.4px;
      text-align: center;
    }
  }
}
.tabledashboard {

    thead tr th{
        height: 50px;
        font-size: 0.8em;
        th{
              border: 0;
              font-size: 0.8rem;
              padding: 0;
              padding-top: 0rem;
        }
    }
   .width-edit{
    button, input, optgroup, select, textarea{
      height: 21px;
    }
   
    } 
  tbody{
        tr{
            cursor: pointer;
            td{
                 height: 30px;
                 max-height: 30px;
                 
                .statuse{
                   width: 2.5rem;
                }
                
                .noneditable{
                  background-color: white;
                  color: black;
                  border: 0;
                  padding: 2px 3px 2px;
                  width:60px
                   
                }
                .edit{
                  background-color: white;
                  padding: 2px 3px 2px;
                  width:60px;
                  height: 20;
                  max-height: 20;
                }
                .faicon .red{
                  margin-top: 8px;
                }
                button{
                  margin-top: -11px;
                }
                .closeit{
                    background: white;
                    border: 0px;
                    margin: 0px;
                }
            }
        }

    } 
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .xs-hidden{
        display: none;
    }
    .xs-show{
        display: inline;
      }
    .xs-show-table{
        display: table-cell;
      }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .xs-hidden{
    display: none;
  }
  .xs-show{
    display: inline;
  }
  .xs-show-table{
    display: table-cell;
  }
}

